<template>
  <div>
    <Loading v-show="injectionLoading" />

    <div v-show="!injectionLoading">
      {{ $store.state?.msg?.message }}
      <h1 v-show="title">
        {{ title }}
      </h1>
      <p v-show="content">
        {{ content }}
      </p>
    </div>
  </div>
</template>

<script>
import msgLazyModule from '@/store/lazy/msg.js'
import Loading from '@/components/Loading.vue';
import dataMixin from '@/mixin/dataMixin.js'; //mixin 

export default {
  components: {
    Loading
  },
  mixins: [dataMixin],//mixin 
  async asyncData ({ store, route }) {
    if (!store.hasModule('msg')) {
      store.registerModule('msg', msgLazyModule);
    }
    console.log('ready to dispatch');
    console.log(666);
    console.log(this);
    console.log(777);
    this.title = await store.dispatch('msg/fetch_data', route.params.id).then(data => {
      console.log('has data', data);
      if (data && typeof data === 'object') {
        if (Array.isArray(data)) {
          return {
            title: data[0]?.title || 'Default Title',
            content: data[0]?.content || 'Default Content'
          };
        } else {
          return {
            title: data.title || 'Default Title',
            content: data.content || 'Default Content'
          };
        }
      } else {
        throw new Error('Invalid data format');
      }
    });
    console.log(this.title, 999);
  },
  data () {
    return {
      title: '',
      content: ''
    };
  },
  created () {
    // if (typeof window === 'undefined') {
    //   this.dataPromise = this.$options.asyncData({
    //     store: this.$store,
    //     route: this.$route
    //   }).then(data => {
    //     console.log('client has data', data);
    //     if (data && typeof data === 'object') {
    //       if (Array.isArray(data)) {
    //         Object.assign(this.$data, {
    //           title: data[0]?.title || 'Default Title',
    //           content: data[0]?.content || 'Default Content'
    //         });
    //       } else {
    //         Object.assign(this.$data, {
    //           title: data.title || 'Default Title',
    //           content: data.content || 'Default Content'
    //         });
    //       }
    //     } else {
    //       console.error('Invalid data format:', data);
    //     }
    //   });
    // }
  },
  methods: {
    handleIndividuationData (data) {
      console.log('handleIndividuationData');
      console.log(data);
      if (data && typeof data === 'object' && !Array.isArray(data)) {
        Object.assign(this.$data, data)
      } else {
        console.error('Invalid data format beforeMount:', data)
      }
    }
  },

};
</script>