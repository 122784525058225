import axios from 'axios' //引入axios

const msg = {
	namespaced: true,
	state: {
		message: 'default msg',
	},
	mutations: {
		setMessage(state, msg) {
			state.message = msg
		},
	},
	actions: {
		//用以客户端和服务端沟通数据
		fetch_message({ commit }, id) {
			console.log('进入请求,准备请求node')
			return axios
				.get('http://localhost:4263/test')
				.then((res) => {
					console.log(res.data)
					commit('setMessage', `id=${id} set ${res.data.estatus}`)
				})
				.catch((e) => {
					console.log(e)
				})
		},
		fetch_data({ commit }, id) {
			console.log('vuex promis')
			return new Promise((resolve) => {
				// 模拟异步数据请求
				setTimeout(() => {
					const data = {
						title: `Title for item ${id}`,
						content: `Content for item ${id}`,
					}
					commit('setMessage', 'mmm')
					resolve('kk')
				}, 1000)
			})
		},
	},
	getters: {
		getMessage: (state) => state.message,
	},
}

export default msg
