// src/mixins/dataMixin.js
export default {
	data() {
		return {
			injectionLoading: true,
		}
	},
	beforeRouteUpdate(to, from, next) {
		// 处理的是同一路由，但是 params 或 query 已更改，例如，从 user/1 到 user/2
		const { asyncData } = this.$options
		if (asyncData) {
			this.injectionLoading = true
			console.log('beforeRouteUpdate has asyncData')
			asyncData({
				store: this.$store,
				route: to,
			})
				.then((data) => {
					this.handleData(data)
					this.injectionLoading = false
					next()
				})
				.catch((err) => {
					console.error('Data fetch error:', err)
					this.injectionLoading = false
					next(err)
				})
		} else {
			next()
		}
	},
	beforeMount() {
		const { asyncData } = this.$options
		if (asyncData) {
			this.injectionLoading = true
			console.log('beforeMount触发页面asyncData')
			this.dataPromise = asyncData({
				store: this.$store,
				route: this.$route,
			})
				.then((data) => {
					this.handleData(data)
					this.injectionLoading = false
				})
				.catch((err) => {
					this.injectionLoading = false
				})
		}
	},
	methods: {
		handleData(data) {
			if (typeof this.handleIndividuationData === 'function') {
				this.handleIndividuationData(data)
			} else {
				if (data && typeof data === 'object' && !Array.isArray(data)) {
					Object.assign(this.$data, data)
				} else {
					console.error('Invalid data format:', data)
				}
			}
		},
	},
	destroyed() {
		this.$store.unregisterModule('msg')
	},
}
